import React from "react";
import { SiteLogo } from "../../Icons";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  let navigate = useNavigate();

  return (
    <footer className="bg-darkGreen flex justify-between py-2">
      <div className="mx-7 md:ml-14">
        <SiteLogo
          onClick={() => navigate('/')}
          className="text-white cursor-pointer w-4 sm:w-12"
        />
      </div>
      <div className="mx-4">
        <div className="grid grid-cols-4 md:grid-cols-4 h-16 text-white justify-items-start content-center">
          <div className="font-dmSans">
            <HashLink
              to="/#section-1"
              className="block text-xs sm:text-[13px] md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px]"          
            >
              Home
            </HashLink>
          </div>
          <div className="font-dmSans">
            <Link
              to="/about"
              className="block text-xs sm:text-[13px] md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px]"
            >
              About
            </Link>
          </div>
          <div className="font-dmSans">
            <Link
              to="/invest"
              className="block text-xs sm:text-[13px] md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px]"
            >
              Contact
            </Link>
          </div>
          <div className="font-dmSans">
            <Link
              to="/founder-notes"
              className="block text-xs sm:text-[13px] md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px]"
            >
              Founder’s note
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
