export default function FounderNotes() {
  return (
    <main>
      <div className="bg-darkGreen text-gray">
        <div className="pt-28 pb-52 font-productSans font-bold text-gray text-[36px] sm:text-[36px] md:text-[36px] lg:text-[48px] xl:text-[52px] 2xl:text-[56px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] text-center md:tracking-wide">
          Fownd's Story
        </div>
      </div>
      <div className="flex justify-center">
        <div className="flex justify-center items-center mt-[-180px] bg-white aspect-auto w-[285px] h-[214px] lg:w-[465px] lg:h-[364px] rounded-[20px]">
          <img
            src="/assets/images/teams/family.png"
            alt="family_image"
            className="w-[260px] h-[184px] lg:w-[420px] lg:h-[314px] aspect-auto rounded-lg"
          />
        </div>
      </div>

      <div className="mx-8 my-8 lg:mx-36 lg:my-8 font-dmSans sm:text-[16px] md:text-[16px] lg:text-[18px] xl:text-[18px] xl:leading-[28px] 2xl:text-[20px] pb-8">
        <p className="mb-8">
          Hi! I am Christina and I have been a practicing Physical Therapist for
          several years and have had the opportunity to work in various PT
          settings, with the majority being outpatient clinics. While I really
          enjoy treating patients, the paperwork side has started to get more
          difficult to keep up with. The requirements seem to grow year after
          year to maintain compliance with insurance companies. Since writing
          notes isn't considered productive work time, I often find myself
          trying to write notes as much as I can while treating multiple
          patients through my lunch breaks, or I end up taking them home with me
          at night.
        </p>
        <p>
          The Fownd team, led by PTs, Software & AI Engineers, & Data
          Scientists, has been working on a better and more efficient way to get
          through notes, which gave me the idea for Fownd. Our PT documentation
          systems have been due for an upgrade for a long time, so it is time to
          revolutionize them by using technology to capture and assist with
          documentation to produce comprehensive and more compliant notes. I
          know we all could use more one-on-one time with patients, downtime
          during lunch breaks, and, most importantly, no paperwork when we get
          home.
        </p>
      </div>
    </main>
  );
}
