export const teamMembers = [
  {
    name: "Dr. Christina Rama",
    imgSrc: "/assets/images/teams/christina-2.png",
    description:
      "I am the founding Subject Matter Expert at FOWND with a doctorate in Physical Therapy, and nearly two decades of experience",
    link: "/team/christina",
  },
  {
    name: "Vignesh Rama",
    imgSrc: "/assets/images/teams/vignesh-2.png",
    description:
      "I am a builder, and technology enthusiast at my core, I bring the technologist and architect skillset as a founding member of the team",
    link: "/team/vignesh",
  },
  {
    name: "Sowmya Shetty",
    imgSrc: "/assets/images/teams/sowmya-2.png",
    description:
      "I’ve always been driven by a lifelong goal to make a positive impact on people’s lives, and that’s what drew me to FOWND and its core mission.",
    link: "/team/sowmya",
  },
  {
    name: "Rachel Stewart",
    imgSrc: "/assets/images/teams/rachel-2.png",
    description:
      "As a UX Designer, my hope is to improve how clinicians interact with documentation system, thus allowing them to provide better patient care.",
    link: "/team/rachel",
  },
  {
    name: "Swagatha Mandal",
    imgSrc: "/assets/images/teams/swagatha-2.png",
    description:
      "I am a techie at heart and an inventor at soul. With 13 years of experience in Cloud Technologies, I am dedicated to connecting technology with humans.",
    link: "/team/swagata",
  },
];
