import { FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";

const Demo = () => {
  const [email, setEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [formStep, setFormStep] = useState(1);
  const [formVisible, setFormVisible] = useState(false);
  const [exists, setExists] = useState(false);
  const [loading, setLoading] = useState(false);

  // Demo Survey form
  const [showSurvey, setShowSurvey] = useState(false);
  const [name, setName] = useState("");
  const [features, setFeatures] = useState("");
  const [considerRadio, setConsiderRadio] = useState("");
  const [testRadio, setTestRadio] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const email_exists = sessionStorage.getItem("authToken");
    if (email_exists) {
      setAuthToken(email_exists);
      setExists(true);
    }
    setTimeout(() => setFormVisible(true), 1200);
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    setSuccess(false);

    // Validate form
    if (!name || !considerRadio || !features || !testRadio) {
      setError("Please fill in all required fields");
      setIsSubmitting(false);
      return;
    }

    const formData = {
      name: name,
      email: email,
      consider: considerRadio,
      features: features,
      willingToTest: testRadio,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEMO_BACKEND_URL}/submitDemoForm`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        setSuccess(true);
        // Reset form
        setName("");
        setConsiderRadio("");
        setFeatures("");
        setTestRadio("");
      }
    } catch (err) {
      setError(
        (err as any).response?.data?.message ||
          "Something went wrong. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const submitFunction = async (e: FormEvent) => {
    e.preventDefault();
    if (formStep === 1) {
      // Sending the Email for OTP
      const emailData = {
        email: email,
        DBCheck: true,
      };
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DEMO_BACKEND_URL}/generateotp`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify(emailData),
          }
        );
        const data = await response.json();
        sessionStorage.setItem("secret_key", data.secret);

        setFormStep(2);
        toast.success("OTP sent to your Email ID.");
      } catch (err) {
        toast.error("Something wrong on our side! Please try again later.");
        console.log("Error while generateotp", err);
      }
    } else {
      // Verify OTP
      setLoading(true);
      const otpData = {
        secretkey: sessionStorage.getItem("secret_key"),
        code: OTP,
        email: email,
      };
      const response = await fetch(
        `${process.env.REACT_APP_DEMO_BACKEND_URL}/verigyotp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(otpData),
        }
      );

      const data = await response.json();

      setLoading(false);
      if (data.acces_token === "otp not worked") {
        toast.error("Wrong Token. Please try again!");
      } else {
        sessionStorage.setItem("authToken", data.acces_token);
        setAuthToken(data.acces_token);
        setExists(true);
      }
    }
  };

  const NotReceivedOTP = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const emailData = {
      email: email,
      DBCheck: true,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEMO_BACKEND_URL}/generateotp`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(emailData),
        }
      );
      const data = await response.json();
      sessionStorage.setItem("secret_key", data.secret);

      setFormStep(2);
      toast.success("OTP sent to your Email ID.");
    } catch (err) {
      toast.error("Something wrong on our side! Please try again later.");
      console.log("Error while generateotp", err);
    }
  };

  if (!exists) {
    return (
      <div className="bg-gray flex flex-col h-[700px] justify-center items-center gap-8 px-4 md:px-0">
        <div className="text-textColor font-productSans font-normal text-center text-[38px] md:text-[56px] tracking-wide">
          <div className="text-darkGreen font-bold tracking-wider overflow-hidden whitespace-nowrap animate-typing max-w-[250px] mx-auto">
            not
            <span className="text-blue">a</span>t
            <span className="text-blue">i</span>
            on
          </div>
          <div className="text-[24px] md:text-[38px] animate-fadeIn">
            Clinical Documentation Platform
          </div>
          <div className="text-green text-[16px] md:text-[20px] animate-fadeIn">
            Powered by FOWND AI
          </div>
        </div>

        {formVisible && (
          <>
            <h4 className="text-textColor font-dmSans font-medium md:text-[20px] text-center animate-fadeIn">
              Get Immediate Access to a Demo Preview
            </h4>

            <form
              onSubmit={submitFunction}
              className="flex flex-row justify-between md:w-[450px] gap-4 animate-fadeIn"
            >
              {formStep === 1 && (
                <input
                  type="text"
                  title="Email ID"
                  value={email}
                  placeholder="Enter your Email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="flex-1 border-[0.5px] text-black focus:outline-none focus:border-[0.5px] bg-white border-textColor focus:border-black p-2 font-normal rounded-md"
                />
              )}

              {formStep === 2 && (
                <input
                  type="text"
                  title="OTP"
                  value={OTP}
                  placeholder="Enter your 6 digit Access Token"
                  onChange={(e) => setOTP(e.target.value)}
                  required
                  className="flex-1 border-[0.5px] text-black focus:outline-none focus:border-[0.5px] bg-white border-gray focus:border-black p-2 font-normal rounded-md"
                />
              )}
              <button className="bg-blue text-white font-medium py-2 px-8 rounded-md">
                {formStep === 1
                  ? "Submit"
                  : loading
                  ? "Verifying..."
                  : "Verify Token"}
              </button>
            </form>

            {formStep === 2 && (
              <div className="text-lightGray text-[14px] animate-fadeIn">
                Did not receive Token?{" "}
                <button
                  onClick={NotReceivedOTP}
                  className="text-blue cursor-pointer"
                >
                  Resend Token
                </button>
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  // User already registered
  else {
    return (
      <>
        <div className="flex flex-col lg:justify-center items-center gap-2 mx-4 md:mx-0">
          <div className="flex flex-col items-center text-center mt-16 mb-2">
            <h2 className="text-textColor font-productSans font-normal text-[28px] md:text-[46px] tracking-wide animate-fadeIn">
              <span className="text-darkGreen font-bold tracking-wider">
                not
                <span className="text-blue">a</span>t
                <span className="text-blue">i</span>
                on
              </span>{" "}
              Clinical Documentation Platform
              <div className="text-green text-[20px]">Powered by FOWND AI</div>
            </h2>

            {/* <button className="flex items-center font-demoSans bg-gray hover:shadow-md text-white font-normal text-[16px] px-6 py-2 mt-4 rounded-md animate-fadeIn">
            Join Waitlist <FaArrowRight className="ml-2" />
          </button> */}
          </div>

          <div className="p-4 lg:p-10">
            {/* Video */}
            <video
              autoPlay
              onPause={() => setShowSurvey(true)}
              controls
              className="max-h-[650px]"
            >
              <source
                src="https://fownd-demo-videos.s3.us-east-1.amazonaws.com/Notation-Demo-Complete.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>

        {showSurvey && (
          <div className="flex flex-col lg:flex-row bg-gray p-8 lg:p-24 m-4">
            <div className="lg:flex-1">
              <div className="font-productSans font-bold text-darkGreen text-[24px] sm:text-[24px] md:text-[28px] lg:text-[42px] xl:text-[42px] 2xl:text-[46px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] text-left md:tracking-wide">
                By Clinicians, For Clinicians. <br />
                We would love your feedback!
              </div>
            </div>

            <form onSubmit={handleSubmit} className="lg:flex-1 py-4 lg:py-0">
              {success && (
                <div className="bg-green-100 border border-textColor text-green px-4 py-3 rounded mb-4">
                  Form submitted successfully!
                </div>
              )}
              <div className="flex flex-col gap-2 py-2">
                <label className="text-[16px] lg:text-[18px] font-medium font-demoSans text-textColor">
                  Full Name{" "}
                </label>
                <input
                  type="text"
                  title="fullName"
                  value={name}
                  placeholder="Enter your name"
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="flex-1 border-[0.5px] text-black focus:outline-none focus:border-[0.5px] bg-white border-textColor focus:border-black p-2 font-normal rounded-md"
                />
              </div>

              <div className="flex flex-col gap-2 py-2">
                <label className="text-[16px] lg:text-[18px] font-medium font-demoSans text-textColor">
                  Are you considering adopting Clinical AI?
                </label>
                <div className="flex gap-4">
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="clinicalAI"
                      value="yes"
                      checked={considerRadio === "yes"}
                      onChange={(e) => setConsiderRadio(e.target.value)}
                      className="form-radio text-blue-500"
                    />
                    <span className="text-[16px] font-demoSans text-textColor">
                      Yes
                    </span>
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="clinicalAI"
                      value="no"
                      checked={considerRadio === "no"}
                      onChange={(e) => setConsiderRadio(e.target.value)}
                      className="form-radio text-blue-500"
                    />
                    <span className="text-[16px] font-demoSans text-textColor">
                      No
                    </span>
                  </label>
                </div>
              </div>

              <div className="flex flex-col gap-2 py-2">
                <label className="text-[16px] lg:text-[18px] font-medium font-demoSans text-textColor">
                  What specific features would you like to see?
                </label>
                <textarea
                  title="features"
                  value={features}
                  placeholder="Tell us about the feature"
                  onChange={(e) => setFeatures(e.target.value)}
                  required
                  className="flex-1 border-[0.5px] text-black focus:outline-none focus:border-[0.5px] bg-white border-textColor focus:border-black p-2 font-normal rounded-md"
                />
              </div>

              <div className="flex flex-col gap-2 py-2">
                <label className="text-[16px] lg:text-[18px] font-medium font-demoSans text-textColor">
                  Would you be willing to test out our Beta product in your
                  clinic or practice?
                </label>
                <div className="flex gap-4">
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="clinicalAI2"
                      value="yes"
                      checked={testRadio === "yes"}
                      onChange={(e) => setTestRadio(e.target.value)}
                      className="form-radio text-blue-500"
                    />
                    <span className="text-[16px] font-demoSans text-textColor">
                      Yes
                    </span>
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="clinicalAI2"
                      value="no"
                      checked={testRadio === "no"}
                      onChange={(e) => setTestRadio(e.target.value)}
                      className="form-radio text-blue-500"
                    />
                    <span className="text-[16px] font-demoSans text-textColor">
                      No
                    </span>
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="clinicalAI2"
                      value="n/a"
                      checked={testRadio === "n/a"}
                      onChange={(e) => setTestRadio(e.target.value)}
                      className="form-radio text-blue-500"
                    />
                    <span className="text-[16px] font-demoSans text-textColor">
                      Not Applicable
                    </span>
                  </label>
                </div>
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="mt-4 bg-blue text-white px-6 py-2 rounded-md hover:bg-blue disabled:bg-blue"
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        )}
      </>
    );
  }
};

export default Demo;
