import { useNavigate, useParams } from "react-router-dom";

export const TeamDetail = () => {
  interface Detail {
    name: string;
    image: string;
    description: string[];
  }

  const { name } = useParams<{ name?: string }>();
  const data: string = name ?? "";

  const navigate = useNavigate();

  const details: Record<string, Detail> = {
    vignesh: {
      name: "VIGNESH RAMA",
      image: "/assets/images/teams/vignesh.png",
      description: [
        "I have two decades of experience building web-scale enterprise technologies for Fortune 500.",
        "I help incubate, scale and take to market new horizon technologies in AI, Virtualization, and Networking. FOWND as a concept can truly transform the Physical Therapy industry, benefiting the profession and patients.",
        "The amalgamation of SaaS technologies and gig economy concepts in healthcare are going to be the catalyst that FOWND brings to bear, to become the gold standard for all healthcare.",
      ],
    },
    christina: {
      name: "DR. CHRISTINA RAMA",
      image: "/assets/images/teams/christina.png",
      description: [
        "I have worked in outpatient, inpatient, neuro rehab, home health, and SNFs. My experience in various states across the US gives me the unique perspective to disrupt the industry with innovation.",
        "Improving lives is at the core of physical therapy, we need to take better care of both therapists and patients. We intend to change how patients and therapists connect to improve overall therapist (provider) sentiment. With FOWND, I have set out to address a severely lacking technology, innovation, and professional satisfaction gap. Our Mission is to provide high-quality, flexible care!",
      ],
    },
    sowmya: {
      name: "SOWMYA SHETTY",
      image: "/assets/images/teams/sowmya.png",
      description: [
        "I am a dentist by education and an entrepreneur in the making. My background in healthcare has been pivotal in helping shape FOWND’s vision to create a better healthcare system, and I am proud to be part of a team that is working to improve the healthcare system one step at a time.",
        " I believe in continuous learning and adaptability, and I wear the team’s orchestrator hat, helping to turn our collective ideas into action.  Together, we’re not just working toward change—we’re building a future where healthcare works better for everyone.",
      ],
    },
    parini: {
      name: "PARINI PAREKH",
      image: "/assets/images/teams/parini.png",
      description: [
        "As a Masters of Design graduate, I have passion for early stage, industry change agent startups. Health Tech needs a modern, minimalist yet sophisticated user experience and design elements.",
        "With FOWND my aim is to simplify the complex interactions between providers, patients and ecosystem of health care technologies.",
      ],
    },
    rachel: {
      name: "RACHEL STEWART ",
      image: "/assets/images/teams/rechal.png",
      description: [
        "I began my journey in healthcare as a Doctor of Physical Therapy. For 6 years, I have worked in a variety of clinical settings only to discover frustration with high volumes of documentation coupled with complex EMR systems. This repetitive experience led me to discover the importance of user experience in the health-tech realm.",
        "As a UX Designer at Fownd, my hope is to improve how clinicians interact with their documentation system, thus allowing them to provide better patient care. Just as I advocated for my patients in the clinic, through Fownd's mission, I continue to advocate for innovative, accessible, and high-quality experiences for our users.. ",
      ],
    },
    pranav: {
      name: "PRANAV KHANDELWAL",
      image: "/assets/images/teams/pranav.png",
      description: [
        "Pravan holds a Phd in Biomechanis with a presidential post doctoroal fellowship @ Virginia Tech. His research career and training has been in Physics and Biology with a mix of Computer Vision. Studying natural unperturbed behavior in biological systems through the lens of Physics.",
        "The challenge  Pranav leads at FOWND is to bring novel computer vision, deep learning and customer modeling of human movement in clinical spaces to standardize best in class rehabilitation and care.",
      ],
    },
    swagata: {
      name: "SWAGATHA MANDAL",
      image: "/assets/images/teams/Swagatha.png",
      description: [
        "I have worked with companies of various sizes to develop cutting-edge technology products and solutions. I am dedicated to digital transformation and shaping the technological landscape, ensuring the establishment of the right strategic technology roadmap and seamless execution.",
        "My goal is to make technology easily accessible and to enhance the quality of life for all users.",
      ],
    },
  };

  const detail: Detail | undefined = details[data];

  // we can make a page for not found if anyone change the url name
  // if (!detail) {
  //   navigate('/not-found');
  // }
  return (
    <section className="relative bg-darkGreen pt-[88px] xl:pt-0">
      <div className="h-12 flex items-center px-6 md:px-16 xl:hidden">
        <button
          type="button"
          onClick={() => navigate("/about")}
          className="material-icons-round text-gray"
        >
          chevron_left
        </button>
      </div>
      <button
        type="button"
        className="xl:inline-flex items-center space-x-3 text-gray absolute top-40 left-48 z-10 mb-0 hidden"
        onClick={() => navigate("/about")}
      >
        <span className="material-icons-round">chevron_left</span>
        <span className="underline text-xl font-archivo">Back</span>
      </button>
      <div className="team-detail-section min-h-screen xl:block hidden">
        <div className="flex items-center justify-center absolute inset-0 left-[6.2rem] 2xl:left-[14.1rem]">
          <div className="w-72">
            <div className="text-gray font-medium font-archivo text-3xl text-center uppercase">
              {detail?.name}
            </div>
          </div>
          <div className="w-[364px] h-[365px] bg-gray flex-none flex items-center justify-center mx-10">
            <img
              src={detail?.image}
              alt={detail?.name}
              className="w-[282px] h-[282px] rounded-[20px] object-cover"
            />
          </div>
          <div className="max-w-sm 2xl:max-w-lg">
            <div className="text-gray font-dmSans text-base space-y-4">
              {detail?.description.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="team-detail-section pb-14 min-h-[calc(100vh-136px)] block xl:hidden">
        <div className="w-[215px] h-[195px] sm:w-[364px] sm:h-[365px] bg-gray flex items-center justify-center mx-auto">
          <img
            src={detail?.image}
            alt={detail?.name}
            className="h-[147px] w-[147px] sm:w-[282px] sm:h-[282px] rounded-md sm:rounded-[20px]"
          />
        </div>
        <div className="pt-5 max-w-[185px] sm:max-w-xs mx-auto">
          <div className="text-darkGreen font-medium font-archivo text-xl sm:text-3xl text-center mb-7">
            {detail?.name}
          </div>
          <div className="text-green font-dmSans text-xs sm:text-base space-y-4">
            {detail?.description.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
