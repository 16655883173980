import { Outlet } from "react-router-dom";

import Navbar from "../Navbar";
import Footer from "../Footer";

const Layout = ({ ...props }) => {
  return (
    <>
      <Navbar {...props} />
      <main className="relative bg-white">
        <Outlet />
      </main>
      <Footer/>
    </>
  );
};

export default Layout;
