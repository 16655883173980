import { Fragment, useState } from "react"
import clsx from "clsx"
import { NavLink } from "react-router-dom"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "../../Icons"
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import { Link } from "react-router-dom"


interface MenuItem {
    title: string
    href?: string
    submenu?: MenuItem[]
}

interface SidebarProps {
    isOpen: boolean
    closeModal: () => void
}

const navigation: MenuItem[] = [
    {
        title: "Home",
        href: "/",
    },
    {
        title: "About us",
        href: "/about",
    },
    {
        title: "Products",
        submenu : [
            {
                title: "Notation AI",
                href: "/demo"
            }
        ]
    },
    {
        title: "Founder's Note",
        href: "/founder-notes"
    },
    {
        title: "Contact",
        href: "/invest"
    }

]

const MenuItem = ({ item, closeModal }: { item: MenuItem; closeModal: () => void }) => {
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

    if (item.submenu) {
        return (
            <div className="space-y-2">
                <button
                    onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}
                    className="flex items-center justify-between w-full font-dmSans text-base sm:text-xl text-gray"
                >
                    <span>{item.title}</span>
                    {isSubmenuOpen ? (
                        <FaChevronUp className="w-4 h-4" />
                    ) : (
                        <FaChevronDown className="w-4 h-4" />
                    )}
                </button>
                {isSubmenuOpen && (
                    <div className="pl-4 space-y-2">
                        {item.submenu.map((subItem) => (
                            <NavLink
                                key={subItem.title}
                                to={subItem.href || '#'}
                                onClick={closeModal}
                                className={({ isActive }) =>
                                    clsx(
                                        isActive ? "font-bold text-beige" : "font-normal",
                                        "block font-dmSans text-md sm:text-lg text-gray outline-none"
                                    )
                                }
                            >
                                {subItem.title}
                            </NavLink>
                        ))}
                    </div>
                )}
            </div>
        )
    }

    return (
        <NavLink
            to={item.href || '#'}
            onClick={closeModal}
            className={({ isActive }) =>
                clsx(
                    isActive ? "font-bold text-beige w-fit" : "font-normal",
                    "font-dmSans text-base sm:text-xl text-gray outline-none"
                )
            }
        >
            {item.title}
        </NavLink>
    )
}

const Sidebar = ({ isOpen, closeModal }: SidebarProps) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-70" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex items-center justify-end pl-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full min-h-screen sm:min-h-screen max-w-[185px] sm:max-w-xs transform overflow-hidden rounded-bl-2xl sm:rounded-l-2xl bg-darkGreen p-6 sm:p-8 text-left align-middle shadow-xl transition-all">
                                <div>
                                    <div className="flex items-center justify-end mb-4">
                                        <XIcon className="cursor-pointer w-3 h-3 sm:w-4 sm:h-4 text-gray" onClick={closeModal} />
                                    </div>
                                    {/* <img src="/assets/images/icons/sidebar.svg" className="w-full" alt="fownd-sidebar" /> */}
                                    <div className="flex flex-col space-y-4 mt-10">
                                        {navigation.map((item) => (
                                            <MenuItem key={item.title} item={item} closeModal={closeModal} />
                                        ))}
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default Sidebar